<template>
  <section style="width:100%">
    <div class="row">
      <div class="block">
        选择多个文件, 文件在一天后自动被清除。
        <input
          multiple
          style="margin: 0px 0px 0px 20px"
          name="myFile"
          type="file"
          ref="myFile"
          @change="getMultiFiles"
        />
      </div>
    </div>
    <div class="row">
      <div class="block">
        <el-button style="margin:0 0 0 20px" type="primary" @click="onUpload">上传</el-button>
      </div>
      <div class="block">
        <span style="margin-left:20px" v-for="(item, index) in todoFiles" :key="index">
          {{ item.name }} : {{ item.percentage }} % ,
        </span>
      </div>
    </div>
    <el-divider></el-divider>
    <div class="row">
      <div class="block">
        <el-button type="primary" @click="onSearch">刷新</el-button>
      </div>
    </div>

    <!--列表-->
    <el-table :data="cosList" highlight-current-row style="width: 100%">
      <el-table-column label="id" width="400">
        <template slot-scope="scope">
          {{ scope.row.fileName }}
        </template>
      </el-table-column>
      <el-table-column prop="updateTime" :formatter="formatTime" label="创建时间"></el-table-column>
      <el-table-column label="操作" width="250">
        <template slot-scope="scope">
          <el-button type="primary" size="small" @click="onOpenTal(scope.$index, scope.row)">打开</el-button>
          <!-- <el-button type="primary" size="small" @click="onDownload(scope.$index, scope.row)">下载</el-button> -->
          <!-- <el-button type="danger" size="small" @click="onDelete(scope.$index, scope.row)">删除</el-button> -->
        </template>
      </el-table-column>
    </el-table>
  </section>
</template>

<script>
import { cosApi } from "@/api/cosApi";
import { epochToDateString } from "@/util/TimeUtil";
import msgLogger from "@/util/msgLogger";
import COS from "cos-js-sdk-v5";
import Axios from "axios";

const LEN = 5;

export default {
  data() {
    return {
      bucket: "",
      region: "",
      cosPrefix: "",

      folderFiles: [],
      multiFiles: [],
      fileDoneCnt: 0,

      todoFiles: [],
      cosList: [],
    };
  },
  mounted() {
    cosApi
      .getInnerShareBoxBucketRegion()
      .then((ret) => {
        console.log("getBucket", ret);
        let data = ret.data;
        this.bucket = data.bucket;
        this.region = data.region;
        this.cosPrefix = data.cosPrefix;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    initCosClient() {
      let cosClient = new COS({
        getAuthorization: function(options, callback) {
          var url = cosApi.COS_STS_URL_INNER_SHARE_BOX;
          var xhr = new XMLHttpRequest();
          xhr.open("GET", url, true);
          xhr.onload = function(e) {
            try {
              var data = JSON.parse(e.target.responseText);
              var credentials = data.credentials;
            } catch (e) {
              console.log(e);
            }
            callback({
              TmpSecretId: credentials.tmpSecretId,
              TmpSecretKey: credentials.tmpSecretKey,
              XCosSecurityToken: credentials.sessionToken,
              ExpiredTime: data.expiredTime,
            });
          };
          xhr.send();
        },
      });
      return cosClient;
    },
    onUpload() {
      if (!this.multiFiles || !this.multiFiles.length) {
        msgLogger.warn("没啥好上传的...");
        return;
      }
      let cosClient = this.initCosClient();
      this.todoFiles = [];
      this.fileDoneCnt = 0;

      for (let i = 0; i < this.multiFiles.length; i++) {
        this.todoFiles.push({
          file: this.multiFiles[i],
          cosKey: this.cosPrefix + "/" + this.multiFiles[i].name,
          name: this.multiFiles[i].name,
          percentage: 0,
        });
      }

      //逐个上传
      for (let i = 0; i < this.todoFiles.length; i++) {
        console.log("...upload one file", this.todoFiles[i]);
        // 分片上传文件
        let _this = this;
        const slice = cosClient.sliceUploadFile(
          {
            Bucket: this.bucket,
            Region: this.region,
            Key: _this.todoFiles[i].cosKey,
            Body: _this.todoFiles[i].file,
            onTaskReady: function(taskId) {
              /* 非必须 */
              //console.log(taskId);
            },
            onHashProgress: function(progressData) {
              /* 非必须 */
              //console.log("hash progress", JSON.stringify(progressData));
            },
            onProgress: function(progressData) {
              /* 非必须 */
              //console.log(JSON.stringify(progressData));
              _this.todoFiles[i].percentage = Math.floor(progressData.percent * 100);
            },
          },
          function(err, data) {
            console.log("...", err, data);
            console.log("##", _this.todoFiles);
            _this.fileDoneCnt++;
            if (_this.fileDoneCnt == _this.todoFiles.length) {
              msgLogger.done("上传成功");
              _this.todoFiles = [];
              _this.multiFiles = [];
              _this.fileDoneCnt = 0;
            }
          }
        );
      }
    },
    formatTime: function(row, column) {
      return epochToDateString(row[column.property]);
    },
    onSearch() {
      cosApi.queryInnerShareBox().then((ret) => {
        if (ret.code == 0) {
          this.cosList = ret.data;
          console.log("on search", this.cosList);
        }
      });
    },
    getMultiFiles(e) {
      this.multiFiles = e.target.files;
      //console.log(this.uploadFiles);
      //this.uploadFileName = this.uploadFiles[0].name;
      //this.cos.fileName = this.uploadFiles[0].name;
      console.log("this.upload zip name", this.multiFiles);
    },
    onOpenTal(index, row) {
      window.open(row.url, "_blank");
    },
    onDownload(index, row) {
      let cosClient = this.initCosClient();
      cosClient.getObject(
        {
          Bucket: this.bucket /* 填入您自己的存储桶，必须字段 */,
          Region: this.region /* 存储桶所在地域，例如ap-beijing，必须字段 */,
          Key: row.cosKey /* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段 */,
        },
        function(err, data) {
          console.log(err || data.Body);
        }
      );

      // var element = document.createElement("a");
      // element.setAttribute("href", row.url);
      // element.setAttribute("download", row.fileName);
      // element.setAttribute("target", "_blank");
      // // Above code is equivalent to
      // // <a href="path of file" download="file name">

      // document.body.appendChild(element);

      // //onClick property
      // element.click();

      // document.body.removeChild(element);
    },
  },
  watch: {},
};
</script>

<style lang="less" scoped>
.row {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 20px;
  .block {
    margin: 10px 20px;
    .input-name {
      width: 200px;
    }
  }
  .grow-block {
    margin: 10px 20px;
    flex: 1;
  }
}

.footer-pager {
  margin: 20px;
}

.cosFileName {
  border-bottom: 1px solid blue;
}
.cosFileName :hover {
  cursor: pointer;
}
</style>
